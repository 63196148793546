<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__nav">
        <router-link v-for="project in projects" :key="project.id"  :to="{ path: '/', hash: `#${Slugify(project.name)}` }" >{{ project.name }}</router-link>
      </div>
      <div class="footer__policy">
        <router-link :to="`/api/legal/privacy/page/${item.id}`" target="_blank">
          <FontAwesomeIcon :icon="['far', 'lock-alt']" size="1x" class="footer__icon" />{{
            $t('privacyPolicy')
          }}
          /
          {{ $t('cookiePolicy') }}
        </router-link>
        <a href="https://malling.no/forbehold" target="_blank">
          <FontAwesomeIcon :icon="['far', 'file-alt']" class="footer__icon" size="1x" />
          <span>Forbehold</span>
        </a>
        <a href="#" @click.prevent="editConsents">{{ $t('editConsents') }}</a>
        <div class="footer__copyright">
          &copy; {{ year }} - {{ $t('pageMadeBy') }}
          <a :href="`https://kvass.no?ref=${currentUrl}`" target="_blank">Kvass</a>
        </div>
      </div>
      <router-link to="/" @click.native="scrollToTop">
        <img :src="logo" class="footer__logo" :alt="item.name" />
      </router-link>
    </div>
    <div class="footer__container-bottom">
      <span>Dette er utviklet av Kvass i samarbeid mellom Malling & Co</span>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import API from '@/api'
import { ScrollToAnchor, Slugify } from '@/utils'

export default {
  props: {
    navigation: Array,
    cookieManager: {
      type: Object,
      defualt: () => ({}),
    },
  },
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
  computed: {
    ...mapState('Root', ['item']),

    address() {
      return this.item.address
    },

    disclaimer() {
      if (!this.item.posts) return

      const disclaimer = this.item.posts.find(p => p.customFieldsConfig[0].source === 'disclaimer')

      return disclaimer
    },
    currentUrl() {
      return window.location.href
    },

    logo() {
      return API.logo
    },

    projects(){
      return JSON.parse(sessionStorage.getItem(`projects:${this.item.id}`))
    }
  },
  methods: {
    editConsents() {
      this.cookieManager.show()
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    navigate() {
      ScrollToAnchor('lead')
    },
    Slugify
  },
}
</script>

<style lang="scss">
.footer {
  --space: 5.75rem;
  --item-gap: 1.1rem;

  background-color: var(--primary);
  color: var(--primary-contrast);



  font-weight: 300;

  .container {


    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.25rem var(--space);
    max-width: 1200px;

    @include respond-below('phone') {
      flex-direction: column;
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }

  a {

    color: var(--primary-contrast);


    line-height: 20px;
    text-decoration: none;
    padding: 0;
    margin: 0;

    &:hover {
      text-underline-offset: 5px;
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  &__container {
    &-bottom {
    color: $lys;
    background-color: var(--footer-bottom);
   
    
      padding: 1rem;
      width: 100%;
   

      @include respond-above('phone') {
        text-align: center;
      }

      a {
        text-underline-offset: 3px;
        color: white;
        text-decoration: underline;

        &:hover {
          text-underline-offset: 3px;
        }
      }
    }
  }

  &__logo {
    width: 115px;

    @include respond-below('phone') {
      order: 1;
    }
  }

  &__icon {
    color: $lys;
    margin-right: .75rem;
  }

  &__policy {
    display: flex;
    gap: var(--item-gap);
    flex-direction: column;

    @include respond-below('phone') {
      order: 3;
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    gap: var(--item-gap);

    @include respond-below('phone') {
      order: 2;
    }
  }
}
</style>
