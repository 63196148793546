<template>
  <Loader :value="promise" theme="default">
    <div
      id="app"
      :class="['route--' + view.toLowerCase(), isOnTop ? 'page--is-top' : 'page--is-scrolled']"
    >
      <div
        v-if="$route.meta.navigation !== false"
        class="nav"
        :class="[
          `nav--${view.toLowerCase()}`,
          { [`nav--${view.toLowerCase()}-expanded`]: isExpanded },
        ]"
      >
        <div class="container nav-container">
          <div class="nav-logo" @click="mainAction">
            <img v-if="logo" :src="logo" alt="Malling logo" />
            <p v-else>{{ projectName }}</p>
          </div>
          <span v-if="recipientLogo && recipientLogo.url" class="nav-logo nav-logo-recipient">
            <img :src="recipientLogo.url" :alt="recipientLogo.description || ''" />
          </span>
        </div>
      </div>
      <router-view />
      <FooterComponent v-if="$route.meta.footer !== false" theme="mini" :cookie-manager="CM" />
    </div>
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FooterComponent from '@/components/Footer'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import loadCM from './gdpr'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      isOnTop: true,
      customItems: [],
      isExpanded: false,
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item', 'promises']),
    logos() {
      return {
        light: API.logoInverted,
        dark: API.logo,
      }
    },
    view() {
      return this.$path('name', this.$route) || ''
    },
    logo() {
      return this.logos.dark
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    projectName() {
      return this.item.name || ''
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    projectIds() {
      return this.$path('item.customFields.selected-projects') || []
    },
    portfolioList() {
      return (this.$path('item.customFields.portfolio-project-list') || []).filter(Boolean)
    },
    externalProjectIds() {
      return this.$path('item.customFields.selected-external-projects') || []
    },
    recipientLogo() {
      return this.$path('item.customFields.portfolio-recipient.logo') || {}
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),

    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || params?.slug != this.$route.query?.slug)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
    mainAction() {
      return window.scrollTo(0, 0)
    },
    fetchProjects() {
      return API.getProjects(this.projectIds)
        .then(async projects => {
          const externals = await API.getExternalProjects(this.externalProjectIds)

          return this.portfolioList
            .map(project => [...projects, ...externals].find(p => p.id === project['project-id']))
            .filter(Boolean)
        })
        .then(projects => {
          const merged = projects.map(project => {
            const info = this.portfolioList.find(item => item['project-id'] === project.id)

            return {
              ...project,
              ...info,
            }
          })

          return merged
        })
    },
  },
  created() {
    const projects = sessionStorage.getItem(`projects:${this.item.id}`)

    this.promise = this.fetch()
      .then(() => (this.CM = loadCM(this)))
      .then(() => {
        if (projects) return JSON.parse(projects)
        return this.fetchProjects()
      })
      .then(res => {
        if (!projects)
          return sessionStorage.setItem(`projects:${this.item.id}`, JSON.stringify(res))
        return
      })
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [
        { rel: 'preload', href: this.logos.dark, as: 'image' },
        { rel: 'preload', href: this.logos.light, as: 'image' },
        { rel: 'icon', href: API.favicon, type: 'image/png' },
      ],
    }
  },
  components: {
    FooterComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.nav {
  position: sticky;
  width: 100%;
  background-color: var(--primary);
  top: 0;
  z-index: get-layer('nav');
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);
  transition: background 150ms ease-in;

  &--page-expanded,
  &--post-expanded {
    padding-bottom: 1rem;
    height: 100%;
  }

  &-container {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
  }

  &-logo {
    padding: 1rem 0rem;
    display: flex;
    cursor: pointer;

    img {
      max-height: 4rem;
    }

    &-recipient {
      img {
        max-width: 13vw;
      }
    }
  }
}

.route-enter,
.route-leave-to {
  opacity: 0;
  transform: scale(0.98);
}

.route-enter-to,
.route-leave {
  opacity: 1;
  transform: scale(1);
}

.route-enter-active,
.route-leave-active {
  transform-origin: top;
  transition: 500ms ease;
}
</style>
