export default function () {
	const get = path => this.$path(path, this.$store.state)

	const isMobile = () => window.innerWidth < 650

	const getProjects = () => JSON.parse(sessionStorage.getItem(`projects:${get('Root.item.id')}`)) || []

	return [
		// Portfolio overview map
		{
			component: 'Section',
			props: {
				id: 'map',
				class: 'section-map',
				width: 'medium',
				style: 'padding: 0;'
			},
			blocks: {
				default: [
					{
						component: 'OverviewMap',
						props: {
							aspectRatio: isMobile() ? '1/1.5' : '2/1',
							mapOptions: {
								dragPan: true,
								doubleClickZoom: false,
							},
						},
						data: {
							coordinates: [0, 0],
							projects: getProjects()
						},
					}
				]
			}
		},

		// Portfolio overview text
		{
			component: 'Section',
			props: {
				id: 'info',
				class: 'section-info',
				width: 'medium',
			},
			blocks: {
				default: {
					component: 'Grid',
					props: {
						columns: '1:3',
						alignItems: 'center'
					},
					blocks: {
						default: [
							{
								component: 'Text',
								condition: !isMobile(),
								data: {
									content: `<p>Viser totalt ${getProjects().length} eiendommer</p>`
								}
							},
							{
								component: 'PageOverview',
								props: {
									projects: getProjects()
								}
							}
						]
					}
				}
			}
		},

		// Project list
		{
			component: 'Section',
			props: {
				id: 'projects',
				class: 'section-projects',
				width: 'medium'
			},
			blocks: {
				default: {
					component: 'Flex',
					props: {
						flexDirection: 'column',
						gap: '2rem'
					},
					blocks: {
						default: {
							component: 'ProjectCardList',
							props: {
								projects: getProjects()
							}
						}
					}
				}
			}
		},
	]
}